import httpUtil from '../utils/httpUtil'

/**
 * 小程序管理
 * @param params
 * @returns {Promise<R>}
 */
export const componentCallBack = params => httpUtil.post("/component/componentCallBack", params);

export const submitPayInfo = params => httpUtil.post("/companyGoods/payment", params);

/**
 * 企业登录强制授权
 *
*/
export const switchCompanyModel = params => httpUtil.post("/company/switchCompanyModel", params);

/**
 * 查询企业登录强制授权
 *
*/
export const querySwitchCompanyModel = params => httpUtil.post("/company/querySwitchCompanyModel", params);


/**
 * 按公司查询菜单基础配置
*/
export const queryTabMenu = params => httpUtil.post("/tabMenu/queryTabMenu", params);


/**
 * 添加或者更新公司菜单
*/
export const saveTabMenu = params => httpUtil.post("/tabMenu/saveTabMenu", params);



/**
 * 保存商城模板
*/
export const saveShopModelConfig = params => httpUtil.post("/shopConfig/saveShopModelConfig", params);


/**
 * 更新商城模板
*/
export const updateShopModelConfig = params => httpUtil.post("/shopConfig/updateShopModelConfig", params);


/**
 * 获取商城模板
*/
export const queryShopModelConfig = params => httpUtil.post("/shopConfig/queryShopModelConfig", params);


/**
 * 获取所有模板信息
*/
export const queryShopModelConfigAll = async (params,type) => httpUtil.post("/shopConfig/queryShopModelConfigAll", params,type);



/**
 * 保存商城基础配置
*/
export const saveShopConfig = params => httpUtil.post("/shopConfig/saveShopConfig", params);


/**
 * 查询商城基础配置
*/
export const queryShopConfig = params => httpUtil.post("/shopConfig/queryShopConfig", params);



/***店铺装修，保存模版 */
export const saveShopConfigHome = params => httpUtil.post("/shopConfigPlus/saveShopConfig", params)


/****店铺装修，回显模版 */
export const showShopConfigHome = params => httpUtil.get("/shopConfigPlus/queryShopConfig", params);


